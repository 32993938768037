import React, { useEffect, useState } from "react";
import { Box, Divider } from "@mui/material";
import { Tabs } from "@mui/material";
import { Tab } from "@mui/material";
import { useLocation } from "react-router-dom";
import Appointments from "./Appointments";
import Prescription from "./Prescription";


const DoctorReportDetails = () => {
  const [activeTab, setActiveTab] = useState(0);

  const location = useLocation();
  const { tab } = location.state || 0;
  // console.log(tab);
  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);

  // check if not tab then set to 0
  useEffect(() => {
    if (!tab) {
      setActiveTab(0);
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          p: 2,
          mt: 2,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ display: "flex" }}
        >
          <Tab label="Appointments" />
          <Tab label="Prescription" />
          
        </Tabs>
        <Divider />
        {activeTab === 0 && (
          <Box>
            <Appointments />
          </Box>
        )}
        {activeTab === 1 && (
          <Box>
            <Prescription />
          </Box>
        )}
        
      </Box>
    </div>
  );
};

export default DoctorReportDetails;
