import React, { createContext, useContext, useState, useEffect } from "react";
import apiService from "../../api/apiService.jsx";
// Create SuperAdmin Context
const SuperAdminContext = createContext();

export const SuperAdminProvider = ({ children }) => {
  // Initialize state from sessionStorage or with default values
  const [superAdmin, setSuperAdmin] = useState({
    id: "",
    name: "",
    mobilenumber: "",
    emailid: "",
    role: "",
  });
     // Fetch user info when component mounts or page refreshes
  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      getUserInfo();
    }
  }, []);


 const getUserInfo = async () => {
   try {
    const response = await apiService.getuserInfo();
     if(response.status === 200){
       const user = response.data.userData;
       setSuperAdmin({
        id: user.id,
        name: user.admin_name,
        emailid: user.user_name,
        role: "SuperAdmin",
       });
       
     }
   } catch (error) {
    console.error('Error fetching user info:', error);
   }
 }
  return (
    <SuperAdminContext.Provider value={{ superAdmin, setSuperAdmin }}>
      {children}
    </SuperAdminContext.Provider>
  );
};
export const useSuperAdmin = () => useContext(SuperAdminContext);
