import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
} from "@mui/material";

import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
// import apiService from "../../api/apiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "@mui/material/Switch";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

const OnboardFacility = ({onDataChange}) => {
  const columns = [
    { id: "sno", label: "S.No", minWidth: 10, align: "center" },
    { id: "facilityId", label: "Facility Id", minWidth: 75, align: "center" },
    {
      id: "facilityName",
      label: "Facility Name",
      minWidth: 75,
      align: "center",
    },
    { id: "contact", label: "Contact No.", minWidth: 75, align: "center" },
    {
      id: "registration",
      label: "Registration Date",
      minWidth: 75,
      align: "center",
    },
    { id: "region", label: "Region", minWidth: 75, align: "center" },
    { id: "actions", label: "Actions", minWidth: 75, align: "center" },
  ];

  const [countData, setCountData] = useState({
    daily_count: "",
    monthly_count: "",
    total_count: "",
    weekly_count: "",
    yearly_count: "",
    six_monthly_count: "",
  });

  const downloadData = [
    {
      title: "Daily Registrations",
      count: countData.daily_count,
      color: "#ff4081",
    },
    {
      title: "Weekly Registrations",
      count: countData.weekly_count,
      color: "#2196f3",
    },
    {
      title: "Monthly Registrations",
      count: countData.monthly_count,
      color: "#ff9800",
    },
    {
      title: "6 Months Registrations",
      count: countData.six_monthly_count,
      color: "#4caf50",
    },
    {
      title: "Yearly Registrations",
      count: countData.yearly_count,
      color: "#9c27b0",
    },
  ];

  const filterMap = {
    Daily: "1days",
    Weekly: "7days",
    Monthly: "1months",
    "6 Months": "6months",
    Yearly: "1year",
  };

  const filters = Object.keys(filterMap);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [period, setPeriod] = useState(filterMap[""]);
  const [searchTerm, setSearchTerm] = useState("");
  //   const [checked, setChecked] = useState(true);
  const [rows, setRows] = useState([]);

  // ab-edits
  useEffect(() => {
    onDataChange(rows);
  }, [rows, onDataChange]);
  // 

  useEffect(() => {
    fetchFacilityData();
  }, [period]);

  const page = 1;

  const fetchFacilityData = async () => {
    try {
      const res = await axios.post(
        "http://192.168.1.14:5091/facility/alluser",
        {
          period,
          page,
        }
      );

      const resData = res.data.data.data[1];
      const countsData = res.data.data.data[0];

      setRows(resData);
      setCountData((prev) => ({
        ...prev,
        daily_count: countsData.daily_count,
        monthly_count: countsData.monthly_count,
        total_count: countsData.total_count,
        weekly_count: countsData.weekly_count,
        yearly_count: countsData.yearly_count,
        six_monthly_count: countsData.six_monthly_count,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    setPeriod(filterMap[filter]);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    console.log(`Search term: ${value}`);
  };

  // Filter rows based on search term
  const filteredRows = rows.filter((row) =>
    Object.values(row).some((val) =>
      String(val).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleChange = (value) => {
    console.log(value);
    // setChecked(event.target.checked);
  };

  const handleClick = () => {
    alert("Click");
  };

  return (
    <div>
      {/* Cards Section */}
      <Paper sx={{ display: "flex", justifyContent: "space-around", p: 1 }}>
        <Box sx={{ display: "flex", gap: 2, overflowX: "auto", padding: 2 }}>
          {downloadData.map((item, index) => (
            <Card
              key={index}
              sx={{ minWidth: 225, borderRadius: 3, boxShadow: 3 }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">{item.title}</Typography>
                  <LocalHospitalIcon sx={{ color: item.color }} />
                </Box>
                <Typography
                  variant="h4"
                  sx={{ mt: 2, color: item.color, fontWeight: "bold" }}
                >
                  {item.count}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Paper>

      {/* Filter Buttons and Search */}
      <Paper sx={{ width: "100%", overflow: "hidden", mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "nowrap",
            overflowX: "auto",
            alignItems: "center",
            padding: 2,
          }}
        >
          {filters.map((filter, index) => (
            <Button
              key={index}
              variant={selectedFilter === filter ? "contained" : "outlined"}
              onClick={() => handleFilterClick(filter)}
              sx={{ minWidth: "100px", whiteSpace: "nowrap" }}
            >
              {filter}
            </Button>
          ))}

          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            size="small"
            sx={{ minWidth: "200px", flexGrow: 1 }}
          />
        </Box>

        {/* Data Table */}
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow sx={{ bgcolor: "grey" }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                    align={column.align}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(filteredRows.length > 0 ? filteredRows : rows).map(
                (row, index) => (
                  <TableRow key={row.facilityId || index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.fclty_id}</TableCell>
                    <TableCell align="center">{row.fclty_nm}</TableCell>
                    <TableCell align="center">{row.fclty_cell_num}</TableCell>
                    <TableCell align="center">
                      {" "}
                      {dayjs(row.created_at).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="center">{row.fclty_address}</TableCell>
                    <TableCell
                      align="center"
                      sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                    >
                      <IconButton
                        color="primary"
                        onClick={handleClick}
                        size="small"
                      >
                        <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                      </IconButton>
                      <IconButton
                        sx={{ color: "red" }}
                        onClick={handleClick}
                        size="small"
                      >
                        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                      </IconButton>
                      <IconButton
                        color="success"
                        onClick={handleClick}
                        size="small"
                      ></IconButton>
                      <Switch
                        checked={row.active}
                        onChange={() => handleChange(row.patientId)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default OnboardFacility;
