// import React, { useEffect, useState } from "react";
// import { Box, Divider } from "@mui/material";
// import { Tabs } from "@mui/material";
// import { Tab } from "@mui/material";
// import { useLocation } from "react-router-dom";

// import OnboardPateint from "./OnboardPateint";
// import OnboardDoctor from "./OnboardDoctor";
// import OnboardFacility from "./OnboardFacility";

// const ResReportDetails = () => {
//   const [activeTab, setActiveTab] = useState(0);

//   const location = useLocation();
//   const { tab } = location.state || 0;
//   // console.log(tab);
//   useEffect(() => {
//     setActiveTab(tab);
//   }, [tab]);

//   // check if not tab then set to 0
//   useEffect(() => {
//     if (!tab) {
//       setActiveTab(0);
//     }
//   }, []);

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   return (
//     <div>
//       <Box
//         sx={{
//           width: "100%",
//           bgcolor: "white",
//           p: 2,
//           mt: 2,
//           borderRadius: 2,
//           boxShadow: 3,
//           display: "",
//         }}
//       >
//         <div className="d-flex justify-content-between align-items-center mb-2">
//           <Tabs
//             value={activeTab}
//             onChange={handleTabChange}
//             sx={{ display: "flex" }}
//           >
//             <Tab label="Patient" />
//             <Tab label="Doctor" />
//             <Tab label="Facility" />
//           </Tabs>

//           <button className="btn  btn-success btn-lg  py-1  border-0 rounded-3 transition-all hover:bg-blue-600  focus:ring-4 focus:ring-blue-300">
//           <i className="bi bi-download me-2"></i> Export</button>
//         </div>

//         <Divider />
//         {activeTab === 0 && (
//           <Box>
//             <OnboardPateint />
//           </Box>
//         )}
//         {activeTab === 1 && (
//           <Box>
//             <OnboardDoctor />
//           </Box>
//         )}
//         {activeTab === 2 && (
//           <Box>
//             <OnboardFacility />
//           </Box>
//         )}
//       </Box>
//     </div>
//   );
// };

// export default ResReportDetails;

// import React, { useEffect, useState } from "react";
// import { Box, Divider } from "@mui/material";
// import { Tabs } from "@mui/material";
// import { Tab } from "@mui/material";
// import { useLocation } from "react-router-dom";
// import OnboardPateint from "./OnboardPateint";
// import OnboardDoctor from "./OnboardDoctor";
// import OnboardFacility from "./OnboardFacility";

// const ResReportDetails = () => {
//   const [activeTab, setActiveTab] = useState(0);

//   const location = useLocation();
//   const { tab } = location.state || 0;

//   useEffect(() => {
//     setActiveTab(tab);
//   }, [tab]);

//   useEffect(() => {
//     if (!tab) {
//       setActiveTab(0);
//     }
//   }, []);

//   const handleTabChange = (event, newValue) => {
//     setActiveTab(newValue);
//   };

//   // Function to export data to CSV
//   const exportToCSV = () => {
//     let tableData = [];

//     // Conditional logic based on the active tab
//     if (activeTab === 0) {
//       // Replace this with the actual data from the Patient table
//       tableData = [
//         ["Patient ID", "Name", "Age", "Disease"], // Example headers
//         ["1", "John Doe", "30", "Flu"], // Example data
//         ["2", "Jane Smith", "25", "Cold"],
//       ];
//     } else if (activeTab === 1) {
//       // Replace this with the actual data from the Doctor table
//       tableData = [
//         ["Doctor ID", "Name", "Specialty", "Experience"], // Example headers
//         ["D1", "Dr. Smith", "Cardiology", "10 years"],
//         ["D2", "Dr. Johnson", "Neurology", "8 years"],
//       ];
//     } else if (activeTab === 2) {
//       // Replace this with the actual data from the Facility table
//       tableData = [
//         ["Facility ID", "Name", "Location", "Type"], // Example headers
//         ["F1", "City Hospital", "New York", "Public"],
//         ["F2", "Health Center", "California", "Private"],
//       ];
//     }

//     // Convert tableData to CSV format
//     const csvContent = tableData.map((row) => row.join(",")).join("\n");

//     // Create a Blob from the CSV content
//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

//     // Create a link to download the file
//     const link = document.createElement("a");
//     if (link.download !== undefined) {
//       // Create a URL for the Blob and trigger the download
//       const url = URL.createObjectURL(blob);
//       link.setAttribute("href", url);
//       link.setAttribute("download", "exported_data.csv");
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     }
//   };

//   return (
//     <div>
//       <Box
//         sx={{
//           width: "100%",
//           bgcolor: "white",
//           p: 2,
//           mt: 2,
//           borderRadius: 2,
//           boxShadow: 3,
//           display: "",
//         }}
//       >
//         <div className="d-flex justify-content-between align-items-center mb-2">
//           <Tabs
//             value={activeTab}
//             onChange={handleTabChange}
//             sx={{ display: "flex" }}
//           >
//             <Tab label="Patient" />
//             <Tab label="Doctor" />
//             <Tab label="Facility" />
//           </Tabs>

//           <button
//             className="btn btn-success btn-lg py-1 border-0 rounded-3 transition-all hover:bg-blue-600 focus:ring-4 focus:ring-blue-300"
//             onClick={exportToCSV} // Attach export functionality here
//           >
//             <i className="bi bi-download me-2"></i> Export
//           </button>
//         </div>

//         <Divider />
//         {activeTab === 0 && (
//           <Box>
//             <OnboardPateint />
//           </Box>
//         )}
//         {activeTab === 1 && (
//           <Box>
//             <OnboardDoctor />
//           </Box>
//         )}
//         {activeTab === 2 && (
//           <Box>
//             <OnboardFacility />
//           </Box>
//         )}
//       </Box>
//     </div>
//   );
// };

// export default ResReportDetails;

import React, { useEffect, useState } from "react";
import { Box, Divider, Tabs, Tab } from "@mui/material";
import { useLocation } from "react-router-dom";
import OnboardPateint from "./OnboardPateint";
import OnboardDoctor from "./OnboardDoctor";
import OnboardFacility from "./OnboardFacility";
import dayjs from "dayjs";

const ResReportDetails = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [patientData, setPatientData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);
  const [facilityData, setFacilityData] = useState([]);

  console.log("patientData", patientData);

  const location = useLocation();
  const { tab } = location.state || 0;

  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);

  useEffect(() => {
    if (!tab) {
      setActiveTab(0);
    }
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Function to handle the export of data to CSV
  const exportToCSV = () => {
    let tableData = [];

    // Fetch data based on the active tab
    if (activeTab === 0) {
      // Patient data
      tableData = [
        [
          "S.No",
          "Patient Id",
          "Patient name",
          "Contact No.",
          "Installed Date",
          "Schedule",
          "Reminder",
        ], // Example headers
        ...patientData.map((patient, index) => [
          index,
          patient.id,
          patient.firstname,
          patient.mobilenumber,
          dayjs(patient.created_at).format("DD/MM/YYYY"),
        ]),
      ];
    } else if (activeTab === 1) {
      // Doctor data
      tableData = [
        [
          "S.No",
          "Doctor Id",
          "Doctor name",
          "Contact No.",
          "facility",
          "Region",
        ], // Example headers
        ...doctorData.map((doctor, index) => [
          index,
          doctor.id,
          doctor.doctorname,
          doctor.mobilenumber,
          doctor.facility,
          doctor.address,
        ]),
      ];
    } else if (activeTab === 2) {
      // Facility data
      tableData = [
        [
          "S.No",
          "Doctor Id",
          "Doctor name",
          "Contact No.",
          "facility",
          "Region",
        ], // Example headers
        ...facilityData.map((facility, index) => [
          index,
          facility.id,
          facility.doctorname,
          facility.mobilenumber,
          facility.facility,
          facility.address,
        ]),
      ];
    }

    // Convert tableData to CSV format
    const csvContent = tableData.map((row) => row.join(",")).join("\n");

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link to download the file
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "exported_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Callback to receive patient data from OnboardPateint component
  const handlePatientData = (data) => {
    setPatientData(data);
  };

  // Callback to receive doctor data from OnboardDoctor component
  const handleDoctorData = (data) => {
    setDoctorData(data);
  };

  // Callback to receive facility data from OnboardFacility component
  const handleFacilityData = (data) => {
    setFacilityData(data);
  };

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          p: 2,
          mt: 2,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <div className="d-flex justify-content-between align-items-center mb-2">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            sx={{ display: "flex" }}
          >
            <Tab label="Patient" />
            <Tab label="Doctor" />
            <Tab label="Facility" />
          </Tabs>

          <button
            className="btn btn-success btn-lg py-1 border-0 rounded-3 transition-all hover:bg-blue-600 focus:ring-4 focus:ring-blue-300"
            onClick={exportToCSV} // Export functionality
          >
            <i className="bi bi-download me-2"></i> Export
          </button>
        </div>

        <Divider />
        {activeTab === 0 && (
          <Box>
            <OnboardPateint onDataChange={handlePatientData} />
          </Box>
        )}
        {activeTab === 1 && (
          <Box>
            <OnboardDoctor onDataChange={handleDoctorData} />
          </Box>
        )}
        {activeTab === 2 && (
          <Box>
            <OnboardFacility onDataChange={handleFacilityData} />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default ResReportDetails;
