import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-toastify/dist/ReactToastify.css";
import { SuperAdminProvider } from "./superadmin/context/LoginSuperAdminAndSupportContext";
import { SearchPatientDataProvider } from "./superadmin/context/SearchPatientData";
import { SearchDoctorDataProvider } from "./superadmin/context/SearchDoctorData";
import { AllFacilityDataProvider } from "./superadmin/context/AllFacilityData";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
  <SuperAdminProvider>
    <SearchPatientDataProvider>
      <SearchDoctorDataProvider>
        <AllFacilityDataProvider>
          <App />
        </AllFacilityDataProvider>
      </SearchDoctorDataProvider>
    </SearchPatientDataProvider>
  </SuperAdminProvider>
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
