import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  IconButton,
} from "@mui/material";

import apiService from "../../../api/apiService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Switch from "@mui/material/Switch";
import axios from "axios";
import dayjs from "dayjs";

const Appointments = () => {
  const columns = [
    { id: "sno", label: "S.No", minWidth: 10, align: "center" },
    { id: "patientid", label: "Patient Id", minWidth: 75, align: "center" },
    { id: "patientname", label: "Patient name", minWidth: 75, align: "center" },
    { id: "contact", label: "Contact No.", minWidth: 75, align: "center" },
    {
      id: "appointment",
      label: "Appointment",
      minWidth: 75,
      align: "center",
    },
    { id: "appointment", label: "Appointment Date", minWidth: 75, align: "center" },
    { id: "status", label: "Status", minWidth: 50, align: "center" },
  ];

  const filterMap = {
    Daily: "1days",
    Weekly: "7days",
    Monthly: "1months",
    "6 Months": "6months",
    Yearly: "1year",
  };

  const filters = Object.keys(filterMap);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [period, setPeriod] = useState(filterMap[""]);
  const [searchTerm, setSearchTerm] = useState("");

  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchPatientData();
  }, [period]);

  const page = 1;
  const fetchPatientData = async () => {
    try {
      // const res = await apiService.getOnboardPatient({ period });
      const res = await axios.post("http://192.168.1.19:5051/patient/alluser", {
        period,
        page,
      });
      const resData = res.data.data.data[1];
      const countsData = res.data.data.data[0];
      console.log(countsData);

      setRows(resData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    setPeriod(filterMap[filter]);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    console.log(`Search term: ${value}`);
  };

  // Filter rows based on search term
  const filteredRows = rows.filter((row) =>
    Object.values(row).some((val) =>
      String(val).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleChange = (value) => {
    console.log(value);
    // setChecked(event.target.checked);
  };

  const handleClick = () => {
    alert("Click");
  };

  return (
    <div>
      {/* Filter Buttons and Search */}
      <Paper sx={{ width: "100%", overflow: "hidden", mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "nowrap",
            overflowX: "auto",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
          }}
        >
          {filters.map((filter, index) => (
            <Button
              key={index}
              variant={selectedFilter === filter ? "contained" : "outlined"}
              onClick={() => handleFilterClick(filter)}
              sx={{ minWidth: "100px", whiteSpace: "nowrap" }}
            >
              {filter}
            </Button>
          ))}

          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            size="small"
            sx={{ minWidth: "200px", flexGrow: 1 }}
          />
        </Box>

        {/* Data Table */}
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow sx={{ bgcolor: "grey" }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                    align={column.align}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(filteredRows.length > 0 ? filteredRows : rows).map(
                (row, index) => (
                  <TableRow key={row.patientId || index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.id}</TableCell>
                    <TableCell align="center">{row.firstname}</TableCell>
                    <TableCell align="center">{row.mobilenumber}</TableCell>
                    <TableCell align="center">
                      {dayjs(row.created_at).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="center">{row.schedule}</TableCell>
                    <TableCell align="center">{row.reminder}</TableCell>

                    <TableCell
                      align="center"
                      sx={{ display: "flex", justifyContent: "center", gap: 1 }}
                    >
                      <IconButton
                        color="primary"
                        onClick={handleClick}
                        size="small"
                      >
                        <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                      </IconButton>
                      <IconButton
                        sx={{ color: "red" }}
                        onClick={handleClick}
                        size="small"
                      >
                        <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
                      </IconButton>
                      <Switch
                        checked={row.active}
                        onChange={() => handleChange(row.patientId)}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                          "& .MuiSwitch-thumb": {
                            backgroundColor: row.active ? "green" : "red",
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default Appointments;
